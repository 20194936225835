<template>

</template>
<script>
import tip from "./config"
export default {
  name: 'index',
  data() {
    return {
      tip
    }
  },
  methods: {
    open() {
      this.$alert(this.tip, '', {
        confirmButtonText: '好的',
        dangerouslyUseHTMLString: true,
        center: true,
        showClose: false
      });
    }
  }
}
</script>

<style lang="scss">
.contact-open-tips {
  display: flex;
  flex-direction: column;

  .tips-box {
    display: flex;
    align-items: center;

    .el-icon-warning {
      color: #FAAD14;
      font-size: 20px;
      margin-right: 17px;
    }
  }

  .qrcode {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .qr-item {
      .icon {
        padding: 0 26px;
        margin-bottom: 19px;

        img {
          width: 110px;
          height: 110px;
          margin: 0 auto;
        }
      }

      .tip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;

        .logo {
          width: 18px;
          height: 18px;
          margin-right: 6px;

          .svg-icon {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
</style>
