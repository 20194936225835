<template>
  <div class="navbar-box">
    <div style="display: flex;align-items: center;">
      <el-image v-if="backIconState" class="back-icon" fit="contain" :src="$util.crossImg(backImg, 'high')" @click="goBack"></el-image>
      <div style="display: flex; align-items: center;">
        <div v-if="iconList.length >1" class="tba-icon-box" v-show="tabIconsState" :style="{marginLeft: backIconState ? '14px':'20px'}">
          <div v-for="(item,index) in iconList"
               :key="index"
               :class="{ 'icon-box': true,
                          selected:item.type===selectState,
                           notAllowed:tabIconsList.indexOf(item.type)===-1 }"
               @click="handleIconChange(item)">
            <svg-icon class="tab-icon"
                      :icon-class="item.icon"/>
            <div class="name">{{item.name}}</div>
          </div>
        </div>
        <div class="tba-time" v-if="tabTimeState">
          <span class="label">统计时间：</span>
          <el-date-picker
              v-model="tbaTime"
              type="daterange"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :clearable="false"
              ref="tabTime"
              @change="timeChange"
              :picker-options="pickerOptions">
          </el-date-picker>
          <div class="btn-box">
            <el-button type="primary" @click="handleTimeBtnChange(false)">查询</el-button>
            <el-button @click="handleTimeBtnChange(true)">重置</el-button>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex;align-items: center;">
      <div class="tba-btn-box" v-show="tbaBtnState">
        <el-button v-for="(item,index) in tbaBtnArray"
                   class="btn"
                   :type="item.type"
                   :plain="!!item.plain"
                   :key="index"
                   @click="handleBtnClick(item)">
          {{ item.label }}
        </el-button>
      </div>
      <div v-if="systemShowState" class="switch-system-box">
        <template v-if="systemCount >1">
          <span>切换系统</span>
          <el-popover
              placement="bottom"
              width="200"
              trigger="hover">
            <div class="content-system-tooltip">
              <div v-if="merchantLimit.contentServiceType !== -1" :class="{system: true, active: currentSystem ==='content'}" @click="changeSystem('content')">
                <span>CONTENT HUB</span><i v-if="currentSystem === 'content'" class="el-icon-check icon"></i>
              </div>
              <div v-if="merchantLimit.videoServiceType !== -1" :class="{system: true, active: currentSystem ==='video'}" @click="changeSystem('video')">
                <span>智能短视频</span><i v-if="currentSystem === 'video'" class="el-icon-check icon"></i>
              </div>
            </div>
            <div slot="reference" class="current">
              <span>{{currentSystemName}}</span>
              <el-image fit="contain" class="icon" :src="$util.crossImg(switchIcon, 'low')"></el-image>
            </div>
          </el-popover>
        </template>
        <template v-else>
          <div class="current">
            <span>{{currentSystemName}}</span>
            <el-image fit="contain" class="icon" :src="$util.crossImg(switchIcon, 'low')"></el-image>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const backImg = "https://fancy-cc.oss-cn-beijing.aliyuncs.com/content/20221121/1669022567718back.png";
const switchIcon = "https://fancy-cc.oss-cn-beijing.aliyuncs.com/content/20221125/1669356612338switchsystem.png";
import { iconList } from "./config"
export default {
  name: "index",
  computed: {
    ...mapGetters(["merchantLimit", "currentSystem", "systemCount", "platformTypes"]),
    backIconState() {
      return this.$route.meta.backIconState || false;
    },
    /** icon开启状态 */
    tabIconsState() {
      if (!this.$route.meta.tabIconsState) {
        this.$store.commit('user/setPlatformType', null)
      }
      return this.$route.meta.tabIconsState
    },
    /** icon开启列表 */
    tabIconsList() {
      return this.$route.meta.tabIconsList || []
    },
    /** 统计时间组件开启状态 */
    tabTimeState() {
      return this.$route.meta.tabTimeState
    },
    /** 按钮开启状态 */
    tbaBtnState() {
      return this.$route.meta.tbaBtnState
    },
    /** 按钮开启列表 */
    tbaBtnArray() {
      return this.$route.meta.tbaBtnArray || []
    },
    systemShowState() {
      return this.$route.meta.systemShowState || false;
    },
    currentSystemName() {
      return this.currentSystem === 'content' ? 'CONTENT HUB' : '智能短视频'
    },
    iconList() {
      const array = [];
      this.iconAllList.map(item => {
        if(this.platformTypes.find(platform => platform === item.type)) {
          array.push(item);
        }
      })
      return array;
    }
  },
  watch: {
    /** 切换路由初始化 */
    '$route.path'(val, oal) {
      if (!this.isInitPlatform) {
        // 表示子页面没有在页面渲染时主动修改平台需求，则设置默认平台
        this.selectState = this.tabIconsList ? this.tabIconsList[0] : null
        this.iconList.forEach(e => {
          if (e.type === this.selectState) {
            this.handleIconChange(e)
          }
        })
      }
    }
  },
  data() {
    return {
      iconAllList: iconList,
      backImg,
      switchIcon,
      selectState: null,
      /** icon列表 */
      /** 统计时间 */
      tbaTime: [],
      /** 时间选项 */
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      isInitPlatform: false
    }
  },
  created() {
    this.$global_event.$on('changeTime', (time) => {
      this.tbaTime = time;
    })
    this.$global_event.$on('initGlobalTab', (platform) => {
      setTimeout(() => {
        // 子页面需要在页面渲染时主动修改平台
        this.isInitPlatform = true;
        this.handleIconChange({type: platform})
      })
    })
  },
  mounted() {
    if (!this.isInitPlatform) {
      // 表示子页面没有在页面渲染时主动修改平台需求，则设置默认平台
      this.selectState = this.tabIconsList ? this.tabIconsList[0] : null
      this.iconList.forEach(e => {
        if (e.type === this.selectState) {
          this.handleIconChange(e)
        }
      })
    }
  },
  methods: {
    /** icon切换 */
    handleIconChange(item) {
      if (this.tabIconsList.indexOf(item.type) === -1) {
        return false
      }
      this.$store.commit("user/setPlatformType", item.type);
      this.selectState = item.type
      this.$global_event.$emit('globalChangeTab', item);
      this.isInitPlatform = false;
    },
    handleTimeBtnChange(isReset) {
      // 查询和重置按钮事件
      this.tbaTime = isReset ? [] : this.tbaTime;
      this.$global_event.$emit('statisticalTimeConfirm', isReset, this.tbaTime)
    },
    /** 时间切换 */
    timeChange() {
      this.$global_event.$emit('statisticalChangeTime', this.tbaTime)
    },
    /** 按钮点击 */
    handleBtnClick(item) {
      this.$global_event.$emit(item.event, item)
    },
    goBack() {
      this.$router.back();
    },
    changeSystem(system) {
      this.$store.commit("user/setCurrentSystem", {system: system, toIndex: true})
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  .back-icon {
    margin-left: 20px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    cursor: pointer;
  }
  .tba-icon-box {
    background: rgba(0, 0, 0, 0.0300);
    border-radius: 20px;
    display: flex;
    .icon-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 21px;
      padding-right: 8px;
      padding-left: 4px;
      &.notAllowed {
        cursor: not-allowed;
      }

      &.selected {
        background: $--color-primary;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        .name {
          color: #FFFFFF;
        }
        .tab-icon {
          color: #FFFFFF;
        }
      }
      .name {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        font-weight: 500;
      }
      .tab-icon {
        height: 32px;
        width: 32px;
        border-radius: 20px;
        color: rgba(0,0,0,0.45);
      }
    }
  }

  .tba-time {
    margin: 0 20px;
    font-size: 14px;
    display: flex;
    align-items: center;

    .label {
      margin-right: 10px;
    }

    .btn-box {
      margin-left: 20px;
    }
  }

  .tba-btn-box {
    white-space: nowrap;
    margin-right: 40px;

    .btn {
      + .btn {
        margin-left: 24px;
      }
    }
  }
  .switch-system-box {
    margin-left: 150px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,0.65);
    .current {
      cursor: pointer;
      margin-left: 10px;
      padding: 9px 12px;
      display: flex;
      align-items: center;
      font-weight: bold;
      border-radius: 20px;
      border: 1px solid rgba(0,0,0,.1);
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: 9px;
      }
    }
  }
}
</style>
<style lang="scss">
.content-system-tooltip {
  .system {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    color: rgba(0,0,0,0.65);
    .icon {
      margin-left: 14px;
    }
  }
  .active {
    color: #5B61FA;
  }
}
</style>
