import {getCookieToken} from './base'
//import EncryptObj from "@/utils/encrypt";
import config from "@/common/config/base";
import EncryptObj from "@/common/utils/encrypt";
import store from "@/store"
import { Message } from 'element-ui'
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a")
      return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}


/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}


/**
 * 导出csv表格
 */
export function exportExcel(url, data) {
  class Processor {
    constructor(res, type) {
      let result = {
        res: res,
        type: type
      }
      return result;
    }
  };
  return new Promise((resolve, reject) => {
    let xhr;
    xhr = new XMLHttpRequest();
    let api = config.baseUrl + url
    xhr.open('post', api, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", getCookieToken() || store.getters.token);
    xhr.setRequestHeader("platformtype", store.getters.platformType);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function (e) {
      if (this.status === 200) {
        resolve(new Processor(this.response, xhr.getResponseHeader('Content-Type')));
      }
    };
    xhr.onerror = function (err) {
      reject(new Processor(err));
    };
    xhr.send(JSON.stringify(data));
  });
}

export function handleExportExcel(url, data, fileName) {
  return new Promise(resolve => {
    exportExcel(url, data).then(async (res) => {
      console.log('res', res);
      let blob = new Blob([res.res], {type: res.type});
      // 判断是否包含application/json 字符
      if ((res.type).includes('application/json')) {
        let codeValue = JSON.parse(await blob.text())
        Message({
          message: codeValue.message,
          type: 'error'
        });
        return
      }
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let URL = window.URL || window.webkitURL;
        let objectUrl = URL.createObjectURL(blob);
        if (fileName) {
          let a = document.createElement('a');
          if (typeof a.download === 'undefined') {
            window.location = objectUrl;
          } else {
            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          window.location = objectUrl
        }
      }
    }).finally(_ => {
      resolve()
    })
  })
}

/**
 * 随机数
 */
export function getUUID() {
  //Date.now() + "-" + Math.floor(1e7 * Math.random()) + "-" + Math.random().toString(16).replace(".", "") + "-" + String(31242 * Math.random()).replace(".", "").slice(0, 8)
  return Date.now() + Math.random().toString(16).replace(".", "")
}

// export function getLocalCache(key) {
//   // 本地缓存默认使用jsencrypt算法的加密方式
//   let value = localStorage.getItem(key);
//   if (value) {
//     return JSON.parse(EncryptObj.decrypt(value));
//   }
// }

// export function setLocalCache(key, value) {
//   // 本地缓存默认使用jsencrypt算法的加密方式
//   if (value) {
//     const resValue = EncryptObj.encrypt(JSON.stringify(value));
//     localStorage.setItem(key, resValue);
//   }
// }

/** 输出规则化路由 */
export const setRouterRule = function (routerList) {
  let levelData = {}
  let routerRule

  /** 路由格式化装载 */
  const setRouter = (router, backPath) => {
    router.forEach(route => {
      const meta = route.meta || {}
      if (meta.level) {
        levelData[`level.${meta.level}`] ??= {}
        levelData[`level.${meta.level}`][meta.levelBackName || '/'] ??= []
        levelData[`level.${meta.level}`][meta.levelBackName || '/'].push({
          name: route.name,
          path: backPath ? `${backPath}/${route.path}` : route.path,
          title: meta.title,
          icon: meta.icon,
          hideInMenu: meta.hideInMenu,
          level: meta.level,
          sort: meta.sort,
          levelBackName: meta.levelBackName,
          levelClass: meta.levelClass
        })
      }
      setRouter(route.children || [], route.path)
    })
  }
  setRouter(routerList)

  /** 数组排序 */
  const sortArray = (list) => {
    list = list || []
    list.sort(function (a, b) {
      return ((a.sort || list.length) - (b.sort || list.length))
    })
    return list
  }

  /** 路由按层级填充 */
  for (let i = Object.keys(levelData).length; i > 0; i--) {
    const data = levelData[`level.${i}`]
    const backData = levelData[`level.${i - 1}`]
    if (backData) {
      for (const backDataKye in backData) {
        const levelDataItem = backData[backDataKye]
        levelDataItem.forEach(e => {
          e.children = sortArray(data[e.name])
        })
      }
    } else {
      routerRule = sortArray(data['/'])
    }
  }
  return routerRule
}

export const urlConversion = function (path) {
  let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
  path = path.replace(reg, "https://$2$3$4$5$6");
  return path
}

export function getLocalCache(key) {
  // 本地缓存默认使用jsencrypt算法的加密方式
  let value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(EncryptObj.decrypt(value));
  }
}

export function setLocalCache(key, value) {
  // 本地缓存默认使用jsencrypt算法的加密方式
  if (value) {
    const resValue = EncryptObj.encrypt(JSON.stringify(value));
    localStorage.setItem(key, resValue);
  }
}

export function getVideoConfig(url) {
  const videoConfig = {}
  const videoObj = document.createElement("video");
  videoObj.preload = "metadata";
  videoObj.src = url;
  return new Promise((resolve,reject) => {
    videoObj.onloadedmetadata = function (evt) {
      URL.revokeObjectURL(url);
      videoConfig.time = videoObj.duration; // 获取视频的时长
      videoConfig.width = videoObj.videoWidth; //获取视频的宽
      videoConfig.height = videoObj.videoHeight; //获取视频的高
      resolve(videoConfig)
    };
  })
}
