import axios from 'axios'
import store from '@/store'
import baseConfig from '@/common/config/base'
import AxiosResponseCode from '@/common/lib/axios/axiosResponseCode'

/**
 * @description 封装Axios相关配置
 * @link http://www.axios-js.com/zh-cn/
 */
class HttpRequest {
  constructor() {
    /**
     * @link http://www.axios-js.com/zh-cn/docs/#%E8%AF%B7%E6%B1%82%E9%85%8D%E7%BD%AE
     * @description 初始化请求配置
     */
    this.axiosConfig = {}
  }

  /** @description 请求拦截 */
  interceptorsRequest(instance) {
    instance.interceptors.request.use(config => {
      if (store.getters['user/getToken']) {
        config.headers.common['Authorization'] = store.getters['user/getToken']
        config.headers.common['merchant'] = store.getters.merchantLimit.merchantId;
        if (config.data && config.data.cancelPlatform) {
          config.headers.common['platformType'] = null;
        } else {
          config.headers.common['platformType'] = store.getters.platformType;
        }
      }
      return config
    }, error => {
      return Promise.reject(error)
    })
  }

  /** @description 响应拦截 */
  interceptorsResponse(instance) {
    instance.interceptors.response.use(res => {
      /** 移除成功请求 */
      store.commit('router/successCancelToken', {
        key: `${this.axiosConfig.method}${this.axiosConfig.url}${JSON.stringify(this.axiosConfig.data)}${JSON.stringify(this.axiosConfig.params)}`,
      })
      const {data} = res
      /** @description 响应自定义Code处理 */
      let axiosResponseCode = {}
      if (res.config.responseType === 'blob') {
        axiosResponseCode = new AxiosResponseCode(res.config, res.status, data.statusText)
      } else {
        axiosResponseCode = new AxiosResponseCode(res.config, data.code, data.message)
      }
      if (axiosResponseCode.message.state) {
        return Promise.reject(axiosResponseCode.message.messageError)
      } else {
        return data
      }
    }, error => {
      /** 移除成功请求 */
      store.commit('router/successCancelToken', {
        key: `${this.axiosConfig.method}${this.axiosConfig.url}`
      })
      new AxiosResponseCode(error.config, error.code, error.message)
      return Promise.reject(error)
    })
  }

  request(options) {
    const instance = axios.create()
    this.axiosConfig = {
      baseURL: baseConfig.baseUrl,
      timeout: 60000,
      headers: {},
      ...options
    }
    this.interceptorsRequest(instance)
    this.interceptorsResponse(instance)
    /** 记录请求 */
    const source = axios.CancelToken.source()
    this.axiosConfig.cancelToken = source.token
    store.commit('router/addCancelToken', {
      key: `${this.axiosConfig.method}${this.axiosConfig.url}${JSON.stringify(this.axiosConfig.data)}${JSON.stringify(this.axiosConfig.params)}`,
      value: source.cancel
    })
    return instance(this.axiosConfig)
  }
}

const httpRequest = new HttpRequest()

export function request(options) {
  return httpRequest.request(options)
}

export function downloadRequest(options) {
  return httpRequest.request({ ...options, timeout: 60000 })
}
