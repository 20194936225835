import Vue, { provide } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/common/api/index.js";
import config from "@/common/config/base.js";
import filter from "@/common/config/filter";
import install from "@/common/config/install";
import "@/assets/svgIcons"; // icon
import "@/assets/iconfont/iconfont.css";
import VCharts from "v-charts";
import { getUUID } from "@/common/utils/base";
import ElementUI from "element-ui";
import "@/assets/style/element.scss";
import contact from "@/components/contact";
Vue.config.productionTip = false;

/** 注册全局配置 */
Vue.use(config);
/** 注册接口方法 */
Vue.use(api);
/** 注册UI组件 */
Vue.use(ElementUI);
/** 注册全局过滤器 */
Vue.use(filter);
Vue.use(install);
Vue.use(VCharts);
Vue.prototype.$contact = contact.install;
Vue.prototype.$bus = new Vue();
/** 注册元素变化指令 */
Vue.directive("resize", {
  bind(el, binding) {
    let width = "",
      height = "";

    function get() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value({ width, height });
      }
      width = style.width;
      height = style.height;
    }

    el.__vueReize__ = setInterval(get, 200);
  },
  unbind(el) {
    clearInterval(el.__vueReize__);
  },
});

/** 注册循环唯一key指令 */
Vue.directive("key-id", {
  bind(el, binding, vnode) {
    vnode.key = getUUID();
  },
});

const $vue = new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$global_event = this;
  },
  setup() {
    provide("store", store);
    // provide("router", router);
    // provide("route", router.currentRoute);
  },
}).$mount("#app");

window.vm = $vue;
