<template>
  <div class="scroll-box" ref="scrollBox" v-resize="handleResize">
    <div class="scroll"
         :class="{
            scrollY:scrollY,
            scrollX:!scrollY&&scrollX
         }"
         @scroll="handleScroll"
         ref="scroll">
      <div class="scroll-content" :style="{
        width: setScrollWidth,
        height:setScrollHeight
      }">
        <slot name="scroll-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    scrollY: {
      type: Boolean,
      default: false
    },
    scrollX: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollWidth: 0,
      scrollHeight: 0,
      timer: null
    }
  },
  computed: {
    setScrollWidth() {
      this.$nextTick(() => {
        this.scrollWidth = `${this.$refs.scrollBox.clientWidth}px`
      })
      return this.scrollY ? this.scrollWidth : '100%'
    },
    setScrollHeight() {
      this.$nextTick(() => {
        this.scrollHeight = `${this.$refs.scrollBox.clientHeight}px`
      })
      return !this.scrollY && this.scrollX ? this.scrollHeight : '100%'
    }
  },
  methods: {
    handleScroll() {
      if(this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('scrollTop', this.$refs.scroll.scrollTop)
      }, 300)
    },
    handleResize() {
      this.$nextTick(() => {
        this.scrollWidth = `${this.$refs.scrollBox?.clientWidth}px`
        this.scrollHeight = `${this.$refs.scrollBox?.clientHeight}px`
      })
    }
  },
  mounted() {
    /** TODO 监测指令代替 */
    // /** 创建一个观察器实例并传入回调函数 */
    // const observer = new MutationObserver(() => {
    //   this.$nextTick(() => {
    //     this.scrollWidth = `${this.$refs.scrollBox?.clientWidth}px`
    //     this.scrollHeight = `${this.$refs.scrollBox?.clientHeight}px`
    //   })
    // });
    // /** 开始观察目标节点 */
    // this.$nextTick(() => {
    //   observer.observe(this.$refs.scrollBox, {attributes: true, childList: true, subtree: true});
    //   observer.observe(this.$refs.scroll, {attributes: true, childList: true, subtree: true});
    // })
  }
}
</script>

<style lang="scss" scoped>
.scroll-box {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .scroll {
    scroll-behavior: smooth;
  }

  .scroll.scrollY {
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .scroll-content {
      display: flex;
      flex-direction: column;
    }
  }

  .scroll.scrollX {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: scroll;

    .scroll-content {
      display: flex;
    }
  }
}

</style>
