<template>
  <div class="side-menu-box">
    <!-- <img :src="head_bg" alt="" class="bg"> -->
    <div class="head row">
      <div class="logo">
        <img :src="fancy_logo" />
      </div>
      <div class="link row">
        <div
          class="item"
          :style="getSystemW>1280?'margin-right: 80px':'margin-right: 40px'"
          v-for="(router, index) in routers"
          :key="index"
          @click="handleToRouter(router)"
        >
          <div class="icon">{{ router.children[0].meta.icon }}</div>
          <div
            class="item_icon column"
            :class="router.path.indexOf(currentMenuPath) !== -1 ? 'act' : ''"
          >
            <div class="title">{{ router.children[0].meta.title }}</div>
            <div class="subtitle">{{ router.children[0].meta.subtitle }}</div>
            <div
              class="dot"
              v-if="router.path.indexOf(currentMenuPath) !== -1"
            ></div>
          </div>
        </div>
      </div>
      <div class="nav-right row">
        <div class="feedback" @click="handleFeedback"><i class="el-icon-edit-outline"></i></div>
        <div class="but-box">
          <el-popover placement="bottom" width="367" trigger="hover">
            <div class="contat-box">
              <div class="title">扫码联系客服</div>
              <div class="qrcode">
                <div class="qr-item">
                  <div class="icon icon-border">
                    <img src="@/assets/images/qywx.png" alt="" />
                  </div>
                  <div class="tip">
                    <div class="logo">
                      <svg-icon icon-class="qiyeweixin"></svg-icon>
                    </div>
                    <div>微信扫码添加</div>
                  </div>
                </div>
                <div class="qr-item">
                  <div class="icon">
                    <img src="@/assets/images/dingding.png" alt="" />
                  </div>
                  <div class="tip">
                    <div class="logo">
                      <svg-icon icon-class="dingding"></svg-icon>
                    </div>
                    <div>钉钉扫码添加</div>
                  </div>
                </div>
              </div>
            </div>
            <svg-icon icon-class="server" slot="reference" style="margin-right: 40px;cursor: pointer;"/>
          </el-popover>
        </div>
        <div class="nav-con">
          <el-dropdown class="avatar-container" trigger="click">
            <div class="avatar-wrapper row">
              <div style="margin-right:10px;width: 98px;overflow: hidden;">
                <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{ merchantName }}</div>
                <div>{{ name }}</div>
              </div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item>
                <span @click="logout" style="display: block">退出</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="handleUpdate" style="display: block"
                  >修改密码</span
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      center
      width="500px"
      :append-to-body="true"
      >
      <el-form
        :model="admin"
        :rules="rules"
        ref="adminForm"
        label-width="150px"
        size="small"
      >
        <el-form-item label="旧密码：" prop="oldPassword">
          <el-input
            v-model="admin.oldPassword"
            placeholder="请输入旧密码"
            type="password"
            style="width: 250px"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input
            v-model="admin.newPassword"
            placeholder="含字母和数字"
            type="password"
            style="width: 250px"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="renewpwd">
          <el-input
            v-model="admin.renewpwd"
            placeholder="含字母和数字"
            type="password"
            style="width: 250px"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <video-menu-feedback ref="feedbackBox"/>
  </div>
</template>

<script>
import scroll from "@/components/scroll";
import { mapGetters } from "vuex";
import { updatePassword } from "@/common/api/base";
export default {
  name: "index",
  data() {
    var validNewPwd = (rule, value, callback) => {
      let reg = /^(?![^a-zA-Z]+$)(?!\D+$)/;
      if (!value) {
        callback(new Error("请输入新密码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入包含字母和数字的密码"));
      } else if (value.length < 3) {
        callback(new Error("密码不能小于3位"));
      } else {
        callback();
      }
    };
    var validReNewPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.admin.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      fancy_logo: require("@/assets/images/login/logo.png"),
      head_bg: require("@/assets/images/head_bg.png"),
      routerList: [],
      dialogVisible: false,
      feedbackVisible:false,
      admin: {
        phone: "",
        oldPassword: "",
        newPassword: "",
        renewpwd: "",
      },
      rules: {
        oldPassword: [{ required: true, message: "旧密码不能为空" }],
        newPassword: [
          { required: true, validator: validNewPwd, trigger: "blur" },
        ],
        renewpwd: [
          { required: true, validator: validReNewPwd, trigger: "blur" },
        ],
      },
    };
  },
  components: {
    scroll,
    videoMenuFeedback: () => import("./components/videoMenuFeedback"),
  },
  computed: {
    ...mapGetters(["routers", "name", "merchantName"]),
    getWorkRouter() {
      return this.$store.getters["router/getWorkRouter"];
    },
    currentRouter() {
      return this.$store.getters["router/getSelectRouter"];
    },
    currentMenuPath() {
      return this.$store.getters["router/getSelectRouter"][0].split("/")[1];
    },
    logoUrl() {
      return this.$store.getters["user/getAvatar"] || this.$config.logoUrl;
    },
    merchantLimit() {
      return this.$store.getters["merchantLimit"];
    },
    currentSystem() {
      return this.$store.getters.currentSystem;
    },
    getSystemW(){
      return document.body.clientWidth;
    },
  },
  mounted() {
  },
  methods: {
    handleFeedback(){
      this.$refs.feedbackBox.open();
    },
    logout() {
      this.$router.push({path: '/login'})
      // this.$store.dispatch("user/FedLogOut").then(() => {
      //   location.reload(); // 为了重新实例化vue-router对象 避免bug
      // });
    },
    handleUpdate() {
      this.dialogVisible = true;
    },
    handleDialogConfirm() {
      this.$refs["adminForm"].validate((valid) => {
        if (valid) {
          this.$confirm("是否要确认修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let data = {
              phone: this.name,
              oldPassword: this.admin.oldPassword,
              newPassword: this.admin.newPassword,
            };
            updatePassword(data).then((res) => {
              if (res.data) {
                this.dialogVisible = false;
                this.$message({
                  message: "修改成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "修改失败！",
                  type: "warning",
                });
              }
            });
          });
        }
      });
    },
    handleToRouter(router) {
      // if (!router.levelClass) {
      //   this.$router.replace(router.path).catch(error => error)
      // }

      // this.$router.replace(router.path).catch(error => error)
      this.$router.replace(router.path).catch((err) => {
        
      });
    },
    toRouter(router) {
      this.$router.push(router).catch((error) => error);
    },
  },
};
</script>
<style lang="scss">
.contat-box {
  padding: 20px 9px 10px;
  .title {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
  }
  .qrcode {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .qr-item {
      .icon {
        padding: 0 26px;
        margin-bottom: 19px;
        img {
          width: 110px;
          height: 110px;
          margin: 0 auto;
        }
      }
      .icon-border {
        border-right: 1px solid #f1f1f1;
      }
      .tip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        .logo {
          width: 18px;
          height: 18px;
          margin-right: 6px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.side-menu-box {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  .head {
    width: 100%;
    height: 80px;
    // background: rgba(245, 245, 247, 0.15);
    // backdrop-filter: blur(10px);
    background: rgba(245, 245, 247, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0 40px;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 140px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .link {
      justify-content: center;
      height: 80px;
      .item {
        margin-right: 40px;
        height: 80px;
        position: relative;
        .icon {
          width: 30px;
          height: 75px;
          font-size: 51px;
          font-weight: bold;
          color: #ffffff;
          line-height: 75px;
          // background: linear-gradient(
          //   90deg,
          //   rgba(200, 200, 201, 1) 0%,
          //   rgba(255, 255, 255, 0) 100%
          // );
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
        .item_icon {
          height: 100%;
          justify-content: center;
          font-size: 16px;
          padding-left: 23px;
          cursor: pointer;
          .title {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
            margin-bottom: 4px;
            white-space: nowrap;
          }
          .subtitle {
            color: rgba(126, 126, 127, 1);
          }
          .dot {
            width: 24px;
            height: 4px;
            background: #5B61FA;
            border-radius: 4px;
            // margin-top: 11px;
            position: absolute;
            left: 23px;
            bottom: 7px;
          }
        }
        .act {
          .title {
            font-weight: bold;
          }
        }
      }
      .item:last-child{
        margin-right: 0 !important;
      }
    }
    .nav-right {
      align-items: center;
      .feedback{
        font-size: 18px;
        margin-right: 40px;
        cursor: pointer;
      }
      .nav-con {
        display: flex;
        align-items: center;
        cursor: pointer;
        .but-box {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          .el-link {
            margin-left: 24px;
          }
        }
      }
    }
  }
  .head-con {
    width: 100%;
    position: absolute;
    top: 80px;
    left: 119px;
    z-index: 1;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  cursor: default;
  //padding: 0 17px;
  .menu-padding {
    padding: 0 17px;
  }

  .logo {
    height: 74px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 20px;

    .logo-img {
      width: auto;
      height: 100%;
    }
  }

  .router {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.none-router {
      opacity: 0.5;
    }

    + .router {
      margin-top: 32px;
    }

    .icon {
      width: 35px;
      height: 35px;
    }

    .text {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.select {
        color: #5b61fa;
      }
    }
  }

  .placeholder {
    flex: 1;
  }

  .item-router-box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateZ(100px) translateX(100%);
    background: #ffffff;
    display: flex;
  }
}
</style>
