import store from '@/store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import {Message} from 'element-ui'
import {getCookieToken} from "@/common/utils/base";
import layout from '@/views/base-view/layout'

class BeforeRouterIntercept {
  constructor(to, from) {
    this.beforeRouterTo = to
    this.beforeRouterFrom = from
  }

  checkPathIntercept() {
    const path = `path${this.beforeRouterTo.fullPath.split('/').join('.')}`
    if (this[path]) {
      return this[path]()
    } else {
      return true
    }
  }

  checkToken() {
    // if (!getCookieToken() && this.beforeRouterTo.fullPath !== '/login') {
    //   return '/login'
    // }
    if (!getCookieToken() && this.beforeRouterTo.fullPath !== '/ad-video') {
      return '/ad-video'
    }
  }

  checkUserInfo(checkToken) {
    return new Promise((resolve, reject) => {
      if (store.getters['user/getRoles'].length === 0) {
        //没有登陆token不调商户信息
        if(!store.getters['user/getToken']&&(checkToken!='/ad-video'||checkToken!='/login')){
          store.dispatch('user/FedLogOut').then(() => {
            reject('/ad-video')
          })
          return false;
        }
        store.dispatch('user/GetInfo').then(res => {
          store.commit('router/setWorkRouter', res.data);
          const localSystem = localStorage.getItem("currentSystem");
          // if(res.data.contentServiceType !== -1 && res.data.videoServiceType !== -1) {
          //   store.commit('user/setCurrentSystem', {system: localSystem, toIndex: false});
          // } else if(res.data.contentServiceType !== -1) {
          //   //只开通内容
          //   const currentSystem = "content";
          //   const isToIndex = localSystem === currentSystem ? false : true;
          //   store.commit('user/setCurrentSystem', {system: currentSystem, toIndex: isToIndex});
          // } else if(res.data.videoServiceType !== -1) {
          //   //只开通微详情
          //   const currentSystem = "video";
          //   const isToIndex = localSystem === currentSystem ? false : true;
          //   store.commit('user/setCurrentSystem', {system: currentSystem, toIndex: isToIndex});
          // }
          resolve()
        }).catch((err) => {
          store.dispatch('user/FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            // reject('/login')
            reject('/ad-video')
          })
        })
      } else {
        resolve()
      }
    })
  }

  'path.studio.service'() {
    window.open('https://content.fancybos.com/')
    return false
  }

  'path.channel.index'() {
    // const serviceType = store.getters['merchantLimit'].serviceType || ''
    // const merchantId = store.getters['merchantLimit'].merchantId || '0'
    // const trialMerchant = store.getters['merchantLimit'].trialMerchant || false
    // const filterList = [1000278, 1000279, 1000280, 1000281, 1000282, 1000187]
    // if (filterList.indexOf(Number(merchantId)) !== -1) {
    //   return {path: '/lite-data/miniDetail'}
    // } else if (serviceType === 'lite') {
    //   return {path: '/lite-channel'}
    // } else if (serviceType === 'double11') {
    //   return trialMerchant ? {path: '/lite-data/mDetail'} : {path: '/lite-data/miniDetail'}
    // } else {
    //   return true
    // }
    return true
  }
}

/**
 * @description 全局前置守卫
 * @param {Route} to 即将要进入的目标 路由对象
 * @param {Route} from 当前导航正要离开的路由
 * @param {function} next 一定要调用该方法来resolve这个钩子。执行效果依赖next方法的调用参数。
 */
export default (to, from, next) => {
  /** 占位菜单 */
  if (to.meta.levelClass) {
    return next(false)
  }
  NProgress.start()
  /** 中止之前路由的网络请求 */
  for (const key in store.getters['router/getCancelToken']) {
    store.commit('router/closeCancelToken', {
      key,
      message: '切换路由取消请求'
    })
  }
  /** 路由前置拦截器类 */
  const beforeRouterIntercept = new BeforeRouterIntercept(to, from)
  /** 检查登录token */
  const checkToken = beforeRouterIntercept.checkToken()  
  if (checkToken&&checkToken!='/ad-video') {
    
    return next(checkToken)
  } else if (to.path === '/ad-video') {
    NProgress.done() // 结束Progress
    return next()
  } else if (to.path === '/login') {
    NProgress.done() // 结束Progress
    return next()
  }
  else if (to.path === '/404'&&from.path==='/') {
    NProgress.done() // 结束Progress
    return next('/ad-video')
  }
  else if (to.path === '/404'&&from.path!=='/') {
    NProgress.done() // 结束Progress
    return next()
  }
  
  /** 判断鉴权 */
  const checkUserInfo = beforeRouterIntercept.checkUserInfo(checkToken)
  checkUserInfo.then(() => {
    /** 路由前置路径处理方法 */
    const checkPathIntercept = beforeRouterIntercept.checkPathIntercept()
    NProgress.done() // 结束Progress
    if (checkPathIntercept) {
      return next(checkPathIntercept)
    } else {
      return next(checkPathIntercept)
    }
  }).catch(err => {
    return next(err)
  })
}
