<template>
  <div class="layout-box" @click="handleMessageOpen">
    <head-menu class="side-menu"/>
    <div class="main-view column">
      <img :src="head_bg" alt="" class="head_bg">
      <div class="head_title">
        <div class="head_con column">
          <div class="title"><span class="t1">{{currentTitle.substring(0,2)}}</span><span class="t2">{{currentTitle.substring(2,4)}}</span></div>
          <div class="dot"></div>
        </div>
        
      </div>
      <div class="content-box"  @scroll="handleScroll">
        <div class="content" :class="{widthFixed:widthFixedState}">
          <router-view/>
        </div>
      </div>
      <div class="footer">
        <div class="title">时代涌现信息科技（南京）有限公司<br/>Copyright © Fancy | <a href="https://beian.miit.gov.cn">苏ICP备2022000248号</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import headMenu from './components/headMenu'
import navbar from './components/navbar'
import { mapGetters } from "vuex";
export default {
  name: 'index',
  components: {headMenu, navbar},
  computed: {
    ...mapGetters(["routers"]),
    navbarState() {
      return !this.$route.meta.navbar
    },
    widthFixedState() {
      return !this.$route.meta.widthFixed
    },
    currentMenuPath() {
      return this.$store.getters['router/getSelectRouter'][0].split("/")[1];
    },
  },
  data() {
    return {
      transitionName: null,
      // head_bg:require('@/assets/images/head_bg.png'),
      head_bg:null,
      currentTitle:""
    }
  },
  watch: {
    currentMenuPath(){
      this.getHeadTitle()
    }
  },
  mounted () {
    this.getHeadTitle()
  },
  methods: {
    getHeadTitle(){
      this.routers.map((item,index)=>{
        let currentPath = '/' + this.currentMenuPath
        if(currentPath==item.path){
          this.currentTitle = item.children[0].meta.title
          this.head_bg = item.children[0].meta.bgImage
        }
      })
    },
    handleScroll(e) {
      // this.$global_event.$emit('globalContentScroll', e)
    },
    handleMessageOpen() {
      this.$bus.$emit('allClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-box {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  // overflow: hidden;
  position: relative;
  background: #F5F5F7;
  // padding-top: 80px;

  .side-menu {
    // background: #FFFFFF;
    // min-width: 130px;
    // width: 130px;
    // height: inherit;
    // box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
    // transform: translateZ(10px);
  }
  /*滚动条样式*/
  .main-view::-webkit-scrollbar {
    width: 4px;    
  }
  .main-view::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .main-view::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0,0,0,0.1);

  }
  .main-view {

    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-top: 80px;
    overflow-y: scroll;
    .head_bg{
      width: 100%;
      height: 464px;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .head_title{
      width: 1200px;
      height: 290px;
      font-size: 64px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
      line-height: 64px;      
      z-index: 2;
      .head_con{
        flex: 1;
        width: 100%;
        height: 290px;
        padding-top: 96px;
      }
      .title{
        margin-bottom: 24px;
        .t1{
          color: #5B61FA;
        }
      }
      .dot{
        width: 46px;
        height: 10px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
      }
    }
    .navbar {
      background: #FFFFFF;
      height: 74px;
      min-height: 74px;
      transform: translateZ(9px);
    }
    .content-box::-webkit-scrollbar {
      display: none;
    }
    .content-box {
      flex: 1;
      width: 1200px;

      .content {
        &.widthFixed {
          min-width: 1100px;
        }

        height: 100%;
      }
    }
    .footer{
      width: 100%;
      padding: 24px 0;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      text-align: center;
    }
  }
}
</style>
