import Vue from "vue"
import Contact from "./contact";
const ContactTipInstance = Vue.extend(Contact);

Contact.install = function() {
  const instance = new ContactTipInstance().$mount();
  document.body.appendChild(instance.$el);
  instance.open();
}
export default Contact;
