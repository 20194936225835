import layout from '@/views/base-view/layout'
const router = [
  {
    path: '/feedback',
    name: 'feedback',
    redirect: '/feedback',
    component: layout,
    children: [
      // {
      //   path: '',
      //   name: 'feedback',
      //   component: () => import('@/views/feedback/index'),
      //   meta: {
      //     title: '效果反馈',
      //     subtitle:'Feedback',
      //     bgImage:require('@/assets/images/router_bg/Feedback.png'),
      //     icon: 'F',
      //   }
      // },
      {
        path: '',
        name: 'feedback',
        component: () => import('@/views/feedbackV2/index'),
        meta: {
          title: '效果反馈',
          subtitle:'Feedback',
          bgImage:require('@/assets/images/router_bg/Feedback.png'),
          icon: 'F',
          // hideInMenu: true
        }
      },
    ]
  },
  {
    path: '/acquirement',
    name: 'acquirement',
    component: layout,
    children: [
      {
        path: '',
        name: 'acquirement',
        component: () => import('@/views/acquirement'),
        meta: {
          title: '信息获取',
          subtitle:'Acquirement',
          bgImage:require('@/assets/images/router_bg/Acquirement.png'),
          icon: 'A',
        }
      },
    ]
  },
  {
    path: '/network',
    name: 'network',
    component: layout,
    children: [
      {
        path: '',
        name: 'network',
        component: () => import('@/views/network/index'),
        meta: {
          title: '渠道分发',
          subtitle:'Network',
          bgImage:require('@/assets/images/router_bg/Network.png'),
          icon: 'N',
        }
      },
    ]
  },
  {
    path: '/creation',
    name: 'creation',
    component: layout,
    children: [
      {
        path: '',
        name: 'creation',
        component: () => import('@/views/creation/index'),
        meta: {
          title: '内容生成',
          subtitle:'Creation',
          bgImage:require('@/assets/images/router_bg/Creation.png'),
          icon: 'C',
        }
      },
      {
        path: 'optimizeVideo',
        name: 'optimizeVideo',
        component: () => import('@/views/creation/optimizeVideo'),
        meta: {
          title: '优化视频',
          subtitle:'Creation',
          bgImage:require('@/assets/images/router_bg/Creation.png'),
          icon: 'C',
        }
      }
    ]
  },
  {
    path: '/money',
    name: 'money',
    component: layout,
    children: [
      {
        path: '',
        name: 'money',
        component: () => import('@/views/money/index'),
        meta: {
          title: '付费中心',
          subtitle:'Money',
          bgImage:require('@/assets/images/router_bg/Money.png'),
          icon: 'Y',
        }
      },
    ]
  }
]

export default router
