import * as base from './base'

const install = function(Vue) {
  Vue.prototype.$api = {
    base: base
  }
}

export default {
  install
}
