import store from '@/store'
import NProgress from 'nprogress' // Progress 进度条
import Vue from "vue"

/**
 * @description 全局前置守卫
 * @param {Route} to 即将要进入的目标 路由对象
 * @param {Route} from 当前导航正要离开的路由
 */
export default (to, from) => {
  NProgress.done() // 结束Progress
  store.commit('router/getSelectRouter', to)
  // 取消页面顶部的平台事件监听
  Vue.prototype.$global_event.$off('globalChangeTab');
  // 取消页面顶部的时间+查询/重置按钮事件监听
  Vue.prototype.$global_event.$off('statisticalTimeConfirm');
  // 取消页面顶部的时间change事件监听
  Vue.prototype.$global_event.$off('statisticalChangeTime');
  // 取消导航栏消息事件监听
  Vue.prototype.$global_event.$off('subjectSelectGetList');
  // Vue.prototype.$global_event.$off('globalContentScroll');
}
