import {Message, MessageBox} from 'element-ui'
import store from '@/store'

/**
 * @description 信息对象
 * @param  config 错误请求信息
 * @param  code 错误码
 * @param  message 错误信息
 */
class AxiosResponseCode {
  #message

  constructor(config, code, message) {
    const messageTips = (this[code] || MessageUtils.messageObject)({message})
    messageTips.messageError = messageTips.state ? MessageUtils.messageLog(config, code, messageTips) : null
    this.#message = messageTips
  }

  /** @readonly 消息对象*/
  get message() {
    return this.#message
  }

  200() {
    return MessageUtils.messageObject({
      message: null,
      state: true
    })
  }

  'ERR_CANCELED'() {
    return MessageUtils.messageObject({
      message: '删除请求码',
      state: true
    })
  }

  400({message}) {
    return MessageUtils.messageObject({
      message: message || null
    })
  }

  401({message}) {
    return MessageUtils.messageObject({
      message: message || null,
      callback: () => {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      }
    })
  }
}

/** 消息工具类 */
class MessageUtils {
  /**
   * @description 信息对象
   * @param {Object} option
   * @param  option.message 错误信息
   * @param  option.state 是否打印
   * @param  option.callback 回调
   */
  static messageObject({message, state, callback}) {
    if (!state) {
      Message({
        message: message || '服务端接口请求异常',
        type: 'error',
        duration: 3000
      })
    }
    return {
      message: message,
      state: !state,
      callback: callback || (() => {
      })
    }
  }

  static messageLog(config, code, message) {
    message.callback()
    return `\n${config.method} ${config.baseURL + config.url}\n错误码：${code}\n错误信息：${message.message}`
  }
}

export default AxiosResponseCode
