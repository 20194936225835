export const crossImg = (url, quality, source = 'oss') => {
  // quality:
  // low 缩略图
  // medium 中图
  // high 大图
  // origin 原图
  // detail 详情页图，只增加 q60
  if (!url) {
    return url
  }
  if (url.indexOf('?') !== -1) {
    return url
  }
  if (Array.isArray(url)) {
    return url.map((u) => {
      return crossImg(u, quality, source)
    })
  }
  if (url.indexOf('xhscdn') !== -1) {
    const xhsQuality = {
      'low': '?imageView2/2/w/300/format/jpg/q/90',
      'medium': '?imageView2/2/w/500/format/jpg/q/90',
      'high': '?imageView2/2/w/1000/format/jpg/q/90',
      'high-dark': '',
      'epic': '?imageView2/2/w/1500/format/jpg/q/90',
      'origin': '',
      'detail': '?imageView2/2/w/540/format/jpg/q/80',
    }
    return url + xhsQuality[quality]
  }
  if (url.indexOf('alicdn') !== -1) {
    const xhsQuality = {
      'low': '_300x300q90.jpg',
      'medium': '_500x500q90.jpg',
      'high': '_800x800q90.jpg',
      'high-dark': '',
      'epic': '_1200x1200q90.jpg',
      'origin': '',
      'detail': '_540x540q80.jpg',
    }
    return url.replace(/.(jpg|png)[\S]*/, function (_, fileType) {
      return '.' + fileType + xhsQuality[quality]
    })
  }
  if (source === 'oss') {
    url = url.replace('//fancy-cc.oss-cn-beijing.aliyuncs.com', '//ccdn.fancybos.com');
    const formatStr = url.indexOf('.jpg')!==-1 ? '/format,jpg' : '';
    const ossQuality = {
      'low': '?x-oss-process=image/resize,w_300,h_300/quality,q_90' + formatStr,
      'medium': '?x-oss-process=image/resize,w_500,h_500/quality,q_90' + formatStr,
      'high': '?x-oss-process=image/resize,w_1000,h_1000/quality,q_90' + formatStr,
      'high-dark': '?x-oss-process=image/resize,w_1000,h_1000/quality,q_90/bright,-20' + formatStr,
      'epic': '?x-oss-process=image/resize,w_1500,h_1500/quality,q_90' + formatStr,
      'origin': '',
      'detail': '?x-oss-process=image/quality,q_80' + formatStr
    }
    return url.replace(/.(jpg|png|webp|jpeg|mp4|gif)$/, function (_, fileType) {
      return '.' + fileType + (fileType === 'gif' ? '' : (ossQuality[quality] || ''))
    })
  }
  return  url;
}
