import {login, getInfo} from "@/common/api/base";
import {getCookieToken, removeCookieToken, setCookieToken} from '@/common/utils/base';
import router from '@/router';
import routerList from "@/router/routerList/router-work"

export default {
  namespaced: true,
  state: {
    routers: routerList,
    token: getCookieToken() || null,
    merchantLimit: {
      accountLimit: 0,           //关注账号限制
      keywordLimit: 0,           //品牌词限制
      merchantId: null,
      launchLimit: null,         //商详浮标投放限制
      serviceType: null,        // 商户版本
      nickName: null,
      trialMerchant: null,      //是否试用
      intelligentRelationProductSwitch: false, //内容智能匹配商品   false,关闭  true   开启
      contentServiceType: -1,    //   内容版本 -1未开通，0试用，1基础版，2高级版
      videoServiceType: -1,       //   智能视频版本  -1未开通，0试用，1正式版
      totalPrice: 0,
      balancePrice: 0,
      comsumePrice: 0,
      tbAuth:false,   //淘宝 AIGC
      ghStatus: 0, //光合状态
      newDataStatBoard: 0, // 是否白名单用户，查看微详情看板数据
    },
    currentSystem: 'content',    //当前所选系统，有：content、video(对应享心动、智能视频)
    platformType: "tb",         // 当前选中的平台
    platformTypes: "tb",        // 商户拥有的平台
    microMainImage: 0,          // 视频可发布位置，0->微详情，1->微详情+主图(补充新概念的时候，已经是int类型了) 2->主图
    platformTypesOptions: [],   // 平台options{key: "", value: ""} 可能用不到
    merchantName: "",
    avatar: "",
    name: "",
    isShowPhotosynthetic: "",
    sycm: null,
    roles: [],
    merchantType: ""
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setMerchantLimit: (state, merchantLimit) => {
      state.merchantLimit.accountLimit = merchantLimit.accountLimit;
      state.merchantLimit.keywordLimit = merchantLimit.keywordLimit;
      state.merchantLimit.merchantId = merchantLimit.merchantId;
      state.merchantLimit.launchLimit = merchantLimit.launchLimit;
      state.merchantLimit.serviceType = merchantLimit.serviceType;
      state.merchantLimit.nickName = merchantLimit.nickName;
      state.merchantLimit.trialMerchant = merchantLimit.trialMerchant;
      state.merchantLimit.intelligentRelationProductSwitch = merchantLimit.intelligentRelationProductSwitch;
      state.merchantLimit.contentServiceType = merchantLimit.contentServiceType;
      state.merchantLimit.videoServiceType = merchantLimit.videoServiceType;
      state.merchantLimit.type = merchantLimit.type;
      state.microMainImage = merchantLimit.microMainImage;
      state.merchantLimit.totalPrice = merchantLimit.totalPrice || 0;
      state.merchantLimit.balancePrice = merchantLimit.balancePrice || 0;
      state.merchantLimit.comsumePrice = merchantLimit.comsumePrice || 0;
      state.merchantLimit.serviceStartTime = merchantLimit.serviceStartTime || '';
      state.merchantLimit.serviceEndTime = merchantLimit.serviceEndTime || '';
      state.merchantLimit.tbAuth = merchantLimit.tbAuth || false;
      state.merchantLimit.optimizeButton = merchantLimit.optimizeButton || 0; 
      state.merchantLimit.ghStatus = merchantLimit.ghStatus || 0; 
      state.merchantLimit.testMerchant = merchantLimit.testMerchant || 0;    //1 演示商户
      state.merchantLimit.newDataStatBoard = merchantLimit.newDataStatBoard || 0;    //1 演示商户


    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setName: (state, name) => {
      state.name = name
    },
    setMerchantName: (state, merchantName) => {
      state.merchantName = merchantName
    },
    setAvatar: (state, avatar) => {
      state.avatar = avatar
    },
    setIsShowPhotosynthetic: (state,isShowPhotosynthetic) => {
      state.isShowPhotosynthetic = isShowPhotosynthetic
    },
    setSycm: (state,sycm) => {
      state.sycm = sycm
    },
    setPlatformTypes: (state, platformTypes) => {
      state.platformTypes = platformTypes
    },
    setPlatformType: (state, platformType) => {
      state.platformType = platformType;
    },
    setMerchantType: (state, merchantType) => {
      state.merchantType = merchantType
    },
    setPlatformTypesOptions: (state, platformTypes) => {
      state.platformTypesOptions = platformTypes.map(pt => ({
        value: pt,
        label: pt === 'tb' ? '天猫' : pt === 'jd' ? '京东自营' : pt === 'wx' ? '微信' : '京东POP'
      }))
    },
    setMenus: (state, menus) => {
      state.menus = menus;
    },
    setCurrentSystem(state, data) {
      state.currentSystem = data.system;
      localStorage.setItem("currentSystem", data.system);
      let historyRouter = localStorage.getItem("historyRouter");
      // 系统切换，同时需要切换路由...
      if (data.system === 'content') {
        this.commit("router/setCurrentSystemRouter", this.getters["router/getContentRouter"])
        if (data.toIndex) {
          router.replace("/channel/index");
        } else {
          historyRouter && router.replace(historyRouter);
        }
      } else {
        this.commit("router/setCurrentSystemRouter", this.getters["router/getVideoRouter"])
        if (data.toIndex) {
          router.replace("/micro-video/microVideo");
        } else {
          historyRouter && router.replace(historyRouter);
        }
      }
    }
  },
  getters: {
    getToken: state => state.token,
    getRoles: state => state.roles,
    getAvatar: state => state.avatar,
    getName: state => state.name,
    getIsShowPhotosynthetic: state => state.isShowPhotosynthetic,
    getSycm: state => state.sycm
  },
  actions: {
    // 获取用户信息
    GetInfo({commit}) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const data = response.data
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('setRoles', data.roles)
          } else {
            reject('用户未设置角色权限')
          }
          commit('setName', data.phone)
          commit('setAvatar', data.icon)
          commit('setMerchantName', data.name)
          commit('setMerchantType', data.merchantType)
          commit('setMerchantLimit', data);
          // 首页详情数据总览展示
          commit('setIsShowPhotosynthetic', data.extConfig?.isShowPhotosynthetic)
          commit('setSycm', data.sycm)
          if (data.platformTypes) { // 验证返回的roles是否是一个非空数组
            const platformTypes = data.platformTypes.split(',');
            //调整下顺序，让tb位于第一个
            const tbIndex = platformTypes.findIndex(i => i === 'tb');
            if (tbIndex !== -1) {
              platformTypes.splice(tbIndex, 1);
              platformTypes.unshift('tb');
            }
            commit('setPlatformType', platformTypes[0])
            commit('setPlatformTypes', platformTypes)
            commit('setPlatformTypesOptions', platformTypes);
            commit('setMenus', data.menus);
          }
          try {
          } catch (e) {
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    handleLogin({commit}, userInfo) {
      const phone = userInfo.phone.trim()
      return new Promise((resolve, reject) => {
        login(phone, userInfo.password).then(response => {
          const data = response.data
          const tokenStr = data.tokenHead + data.token
          setCookieToken(tokenStr)
          commit('setToken', tokenStr)
          resolve(tokenStr)
        }).catch(error => {
          console.error(error)
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut({commit}) {
      return new Promise(resolve => {
        commit('setToken', '')
        removeCookieToken()
        resolve()
      })
    }
  }
}
