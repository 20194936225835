/**
 * @file 路由列表
 */
import routerWork  from './router-work'

/**
 * @description [基础路由]{@link router-item}
 * @type {Array}
 */
export const baseRouter = [
  // {
  //   path: '/',
  //   redirect: '/channel'
  // },
  {
    path: '/ad-video',
    // redirect: '/ad-video',
    name: 'ad-video',
    component: () => import('@/views/base-view/ad-video/index'),
    // children:[
    //   {
    //     path:"",
    //     redirect: '/ad-video',
    //   }
    // ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/base-view/login/index')
  }, 
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/base-view/404")
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
]

/** @description [业务路由]{@link router-item}
 *  @type {Array}
 */
export const workRouter = [
  ...routerWork,
]
// 打印格式化路由
// console.log(setRouterRule(workRouter))
// console.log(JSON.stringify(setRouterRule(workRouter)))
/**
 * @namespace router-item
 * @type {Object}
 * @property {string} path 路由地址
 * @property {string} name 路由别名
 * @property {string} redirect 路由重定向
 * @property {Array} children 嵌套路由
 * @property {Object} meta 路由元信息
 * @property {boolean} meta.title 菜单中显示标题
 * @property {number} meta.level 菜单层级
 * @property {number} meta.sort 同组菜单排序
 * @property {boolean} meta.levelClass 菜单层级类目{true->仅作为父类展示,不可跳转}
 * @property {string} meta.levelBackName 上级菜单名称
 * @property {string} meta.backIconState 左侧返回按钮显示开关
 * @property {string} meta.systemShowState 系统名称显示开关
 * @property {boolean} meta.hideInMenu 菜单中显示开关{true->关闭  false->开启}
 * @property {string} meta.icon 菜单中显示图标地址
 * @property {boolean} meta.navbar 导航栏是否关闭{true->关闭  false->开启}
 * @property {boolean} meta.widthFixed 固定宽度开关{true->关闭  false->开启}
 * @property {boolean} meta.tabIconsState 切换平台是否开启{true->开启  false->关闭}
 * @property {Array} meta.tabIconsList 切换平台开启列表
 * @property {boolean} meta.tabTimeState 统计时间组件{true->开启  false->关闭  statisticalChangeTime->全局事件名}
 * @property {boolean} meta.tbaBtnState 导航栏右边按钮是否开启{true->开启  false->关闭}
 * @property {Array} meta.tbaBtnArray 导航栏按钮信息[{label->按钮名称  event->事件名  type->样式类  plain->样式类}]
 */
