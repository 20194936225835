import JsEncrypt from 'jsencrypt';

// 重要提醒：本文件publicKey和privateKey永久不可修改，否则历史数据会解密失败（用作缓存编码）

const publicKey =`-----BEGIN PUBLIC KEY-----
MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMsTku7s3NjWfAyvdLDR/JBqb+Hn3EIQ
KwDvXUJhKM78YyIwsgn3sgw2iBHY+cT0pB+7BzSxKe1NjfTn2xUxjrkCAwEAAQ==
-----END PUBLIC KEY-----`;

const privateKey =`-----BEGIN RSA PRIVATE KEY-----
MIIBOwIBAAJBAMsTku7s3NjWfAyvdLDR/JBqb+Hn3EIQKwDvXUJhKM78YyIwsgn3
sgw2iBHY+cT0pB+7BzSxKe1NjfTn2xUxjrkCAwEAAQJALsYwZrXJ3GvyoJ1dHEkR
ilXptTfJZLICpbF8YoisdJgF4jGZkvvIWTgvpKk0r67uT6UR1bCNaGi633MtEtlK
8QIhAPprx3mnrsppcwPRTfQboEhSeKuwK2wSe5qRPIQu+IBlAiEAz5nGjQ02Cl5K
RvbF2Q97TBIf0f/Gl5hnw5hYCy0BLcUCIQD0hHjqGQlbK0va1my/hpojBCQ/wG0l
wYZfjqEAkLyk4QIgSUMjZrO61n3gCJsdxzRHdtkOkbWKCAWo2MXyjD8do2ECIQDB
RZWiISXt8gmeDiF/X0lalHm7ZuDU4sIA/YQ2L+S/wQ==
-----END RSA PRIVATE KEY-----`;

class Encryptor {
  // 加密
  constructor() {
    this.encryptor = new JsEncrypt();
  }

  encrypt(txt) {
    const encryptor = this.encryptor;
    encryptor.setPublicKey(publicKey)
    return encryptor.encrypt(txt)
  }
  // 解密
  decrypt(txt) {
    const encryptor = this.encryptor;
    encryptor.setPrivateKey(privateKey)
    return encryptor.decrypt(txt)
  }
}

export default new Encryptor();
