import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@//store'
import {baseRouter, workRouter} from '@/router/routerList'
import beforeEach from './lib/beforeEach'
import afterEach from './lib/afterEach'
import {setRouterRule} from "@/common/utils";

Vue.use(VueRouter)
// store.commit('router/setWorkRouter', setRouterRule(workRouter))
const router = new VueRouter({
  routes: [...baseRouter, ...workRouter],
  mode: 'hash'
})
router.beforeEach(beforeEach)
router.afterEach(afterEach)
export default router
