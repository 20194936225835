export default {
  namespaced: true,
  state: {
    /** 业务路由 */
    workRouter: [],
    contentRouter: [],
    videoRouter: [],
    /** 当前路由 */
    selectRouter: [],
    /** 请求列表 */
    cancelToken: {}
  },
  mutations: {
    /** 设置业务路由 */
    setWorkRouter(state, param) {
      const contentRouter = param.contentServiceMenus.data ? [...param.contentServiceMenus.data] : [];
      const videoRouter = param.videoServiceMenus.data ? [...param.videoServiceMenus.data] : [];
      state.contentRouter = contentRouter;
      state.videoRouter = videoRouter;
    },
    /** 设置当前系统对应的路由表 */
    setCurrentSystemRouter(state, router) {
      state.workRouter = router;
    },
    /** 设置当前业务路由列表 */
    getSelectRouter(state, router) {
      const levelBackName = router.meta.levelBackName
      const routerList = {
        children: state.workRouter
      }
      const selectRouter = (list) => {
        let selectList = []
        if (list.children) {
          list.children.forEach(e => {
            if (e.name === levelBackName) {
              selectList.push(e.path)
            } else if (e.children) {
              selectList = selectList.concat(selectRouter(e))
            }
          })
        }
        if (selectList.length && list.path) {
          selectList.push(list.path)
        }
        return selectList
      }
      state.selectRouter = [router.path, ...selectRouter(routerList)];
      localStorage.setItem("historyRouter", window.location.hash.slice(1));
    },
    /** 添加请求 */
    addCancelToken(state, {key, value}) {
      /** 过滤重复请求 */
      if (state.cancelToken[key]) {
        this.commit('router/closeCancelToken', {
          key,
          message: `过滤重复请求:${key}`
        })
      }
      state.cancelToken[key] = value
    },
    /** 删除重复请求 */
    closeCancelToken(state, {key, message}) {
      state.cancelToken[key](message)
      Reflect.deleteProperty(state.cancelToken, key)
    },
    /** 请求成功后删除key */
    successCancelToken(state, {key}) {
      Reflect.deleteProperty(state.cancelToken, key)
    }
  },
  getters: {
    getWorkRouter: state => state.workRouter,
    getSelectRouter: state => state.selectRouter,
    getCancelToken: state => state.cancelToken,
    getContentRouter: state => state.contentRouter,
    getVideoRouter: state => state.videoRouter
  },
  actions: {}
}
