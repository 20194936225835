import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import router from '@/store/modules/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    roles: state => state.user.roles,
    platformTypes: state => state.user.platformTypes,
    microMainImage: state => state.user.microMainImage,
    platformType: state => state.user.platformType,
    platformTypesOptions: state => state.user.platformTypesOptions,
    merchantName: state => state.user.merchantName,
    avatar: state => state.user.avatar,
    merchantLimit: state => state.user.merchantLimit,
    currentSystem: state => state.user.currentSystem,
    systemCount: state => (state.user.merchantLimit.contentServiceType !== -1 && state.user.merchantLimit.videoServiceType !== -1) ? 2:1,
    routers: state => state.user.routers,
    name: state => state.user.name,
    merchantType: state => state.user.merchantType
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    router
  }
})
