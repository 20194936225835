const tip = `<div class="contact-open-tips">
                      <div class="tips-box">
                        <i class="el-icon-warning"></i>
                        <div>暂未开通此功能，请联系您的客户成功咨询开通</div>
                      </div>
                      <div class="qrcode">
                        <div class="qr-item">
                          <div class="icon icon-border">
                            <img src="${require('@/assets/images/qywx.png')}" alt="">
                          </div>
                          <div class="tip">
                            <div class="logo">
                              <svg aria-hidden="true" class="svg-icon">
                                <use xlink:href="#icon-qiyeweixin"/>
                              </svg>
                            </div>
                            <div>微信扫码添加</div>
                          </div>
                        </div>
                        <div class="qr-item">
                          <div class="icon">
                            <img src="${require('@/assets/images/dingding.png')}" alt="">
                          </div>
                          <div class="tip">
                            <div class="logo">
                              <svg aria-hidden="true" class="svg-icon">
                                <use xlink:href="#icon-dingding"/>
                              </svg>
                             </div>
                            <div>钉钉扫码添加</div>
                          </div>
                        </div>
                      </div>
                    </div>`;
export default tip;
