import Cookies from 'js-cookie'

const TokenKey = 'loginToken'

export function getCookieToken() {
  return Cookies.get(TokenKey)
}

export function setCookieToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeCookieToken() {
  return Cookies.remove(TokenKey)
}

/**
 * 随机数
 */
export function getUUID() {
  //Date.now() + "-" + Math.floor(1e7 * Math.random()) + "-" + Math.random().toString(16).replace(".", "") + "-" + String(31242 * Math.random()).replace(".", "").slice(0, 8)
  return Date.now() + Math.random().toString(16).replace(".", "")
}
