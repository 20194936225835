var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleResize),expression:"handleResize"}],ref:"scrollBox",staticClass:"scroll-box"},[_c('div',{ref:"scroll",staticClass:"scroll",class:{
          scrollY:_vm.scrollY,
          scrollX:!_vm.scrollY&&_vm.scrollX
       },on:{"scroll":_vm.handleScroll}},[_c('div',{staticClass:"scroll-content",style:({
      width: _vm.setScrollWidth,
      height:_vm.setScrollHeight
    })},[_vm._t("scroll-content")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }