export const iconList = [
  // {
  //   name: '全部',
  //   type: null,
  //   icon: 'navbar-tab-all'
  // },
  {
    name: '淘宝',
    type: 'tb',
    icon: 'navbar-tab-tb'
  },
  {
    name: '微信',
    type: 'wx',
    icon: 'navbar-tab-wx'
  },
  {
    name: '京东自营',
    type: 'jd',
    icon: 'navbar-tab-jd'
  },
  {
    name: '京东POP',
    type: 'pop',
    icon: 'navbar-tab-jd'
  }
]
