/**
 * @description process自定义配置可见根目录.env.*文件
 * @link https://cli.vuejs.org/zh/guide/mode-and-env.html
 **/
const config = {
  /** @inner 接口请求地址 */
  baseUrl: process.env.VUE_APP_BASE_URL,
  // baseUrl: 'http://192.168.100.34:8401',
  /** @inner LOGO地址 */
  logoUrl: 'http://fancy-cc.oss-cn-beijing.aliyuncs.com/content/20220706/1657112537005032119fa843918.png'
}

/** @export 全局配置 */
export default {
  install: function (Vue) {
    Vue.prototype.$config = config
  },
  ...config
}
