import { formatDate } from "@/common/utils/date";

export default function (Vue) {
  Vue.filter("spDataFormat", value => {
    let spData = value;
    if (!value) {
      return "";
    }
    if (value && typeof value === "string") {
      spData = JSON.parse(value);
    }
    let spStrList = [];
    spData.map(e => e.value && e.key !== "服务" && spStrList.push(e.value));
    if (!spStrList.length) {
      return "";
    }
    return spStrList.join(" | ");
  });

  Vue.filter("priceFormat", value => {
    if (!value) return "0";
    if (!Number(value)) return value;
    value = "" + value;
    const value2Array = value.split(".");
    //整数部分
    const intPart = value2Array[0];
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
    //小数位部分
    let floatPart = "";
    if (value2Array.length == 2) {
      floatPart = "." + value2Array[1];
    }
    return intPartFormat + floatPart;
  });

  Vue.filter("formatDateTime", time => {
    if (time == null || time === "") {
      return "N/A";
    }
    let date = new Date(time);
    return formatDate(date, "yyyy-MM-dd hh:mm:ss");
  });

  Vue.filter("sourceTitle", source => {
    let sourceTitle = "";
    if (source == 'wx') {
      sourceTitle = "微信公众号";
    } else if (source == 'dy') {
      sourceTitle = "抖音";
    }else if (source == 'xhs') {
      sourceTitle = "小红书";
    }else if (source == 'wb') {
      sourceTitle = "微博";
    }else if (source == 'tb') {
      sourceTitle = "逛逛";
    }else if(source == 'pc') {
      sourceTitle = "pc";
    }
    return sourceTitle;
  });
  Vue.filter("filterServiceType", serviceType => {
    let serviceTypeTitle = "";
    if (serviceType == 'basic') {
      serviceTypeTitle = "基础版";
    } else if (serviceType == 'pro') {
      serviceTypeTitle = "高级版";
    }else if (serviceType == 'exclusive') {
      serviceTypeTitle = "定制版";
    }
    return serviceTypeTitle;
  });
  Vue.filter("filterCount", num => {
    if(num == -1 || num == null){
      return '-'
    }else{
      return num
    }
  });
}

