import {request} from '@/common/lib/axios';

export const ddCodeCallback = (params) => {
  return request({
    url: "/doudian/callback/ddCodeCallback",
    method: "get",
    params: params,
  });
};

export const getInfo = () => {
  return request({
    url: '/admin/info',
    method: 'get'
  })
}

export function getCode(data) {
  return request({
    url: '/admin/sendVerificationCode',
    method: 'post',
    data: data
  })
}

export function healthLevel(data) {
  return request({
    url: '/config/healthLevel',
    method: 'post',
    data: data
  })
}

export function login(phone, password) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: {
      phone,
      password,
    }
  })
}

export function updatePassword(data) {
  return request({
    url: '/admin/updatePassword',
    method: 'post',
    data: data
  })
}

export function queryUserGuideStatus(data) {
  return request({
    url: '/articleLite/queryUserGuideStatus',
    method: 'post',
    data: data
  })
}

export function updateUserGuideStatus(data) {
  return request({
    url: '/articleLite/updateUserGuideStatus',
    method: 'post',
    data: data
  })
}

export function addMerchantFeedback(data) {
  return request({
    url: '/chaoMaiWeb/addMerchantFeedback',
    method: 'post',
    data: data
  })
}

export function getVideoMessageList() {
  return request({
    url: '/chaoMaiWeb/getMessageList',
    method: 'post',
    data: {}
  })
}

export function updateVideoMessageRead() {
  return request({
    url: '/chaoMaiWeb/updateMessageRead',
    method: 'post',
    data: {}
  })
}

